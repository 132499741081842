import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f04aecd4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "min-h-screen bg-white" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductLandingBlockLM = _resolveComponent("ProductLandingBlockLM")!
  const _component_ProductDetailsBlock = _resolveComponent("ProductDetailsBlock")!
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_ProductsBlock = _resolveComponent("ProductsBlock")!
  const _component_Error = _resolveComponent("Error")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.product)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ProductLandingBlockLM, { product: _ctx.product }, null, 8, ["product"]),
          _createVNode(_component_ProductDetailsBlock, { product: _ctx.product }, null, 8, ["product"]),
          (_ctx.attachments)
            ? (_openBlock(), _createBlock(_component_ProductsBlock, {
                key: 0,
                products: _ctx.attachments
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BaseHeading, { class: "title" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('product_details_attachments_block_title')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["products"]))
            : _createCommentVNode("", true),
          (_ctx.suggestions?.length)
            ? (_openBlock(), _createBlock(_component_ProductsBlock, {
                key: 1,
                products: _ctx.suggestions,
                isDark: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BaseHeading, { class: "title" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('product_details_suggestions_block_title')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["products"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createBlock(_component_Error, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}