import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4192a566"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-title" }
const _hoisted_2 = { class: "inputs-wrapper" }
const _hoisted_3 = { class: "inputs-wrapper" }
const _hoisted_4 = { class: "flex lg:w-1/2" }
const _hoisted_5 = { class: "submit-button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_BaseTextArea = _resolveComponent("BaseTextArea")!
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.t('lm_product_request_title')), 1),
    _createElementVNode("form", {
      onSubmit: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_ctx.$emit('submit')), ["prevent"]))
    }, [
      _createVNode(_component_InputGroup, {
        label: _ctx.t('lm_request_company_label'),
        error: _ctx.form.companyErr
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BaseInput, {
            modelValue: _ctx.form.company,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.company) = $event)),
            class: "dark-bg",
            name: "company"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label", "error"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_InputGroup, {
          label: _ctx.t('lm_request_firstname_label'),
          error: _ctx.form.firstnameErr,
          class: "lg:w-1/2 lg:mr-2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BaseInput, {
              modelValue: _ctx.form.firstname,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.firstname) = $event)),
              class: "dark-bg",
              name: "firstname"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label", "error"]),
        _createVNode(_component_InputGroup, {
          label: _ctx.t('lm_request_lastname_label'),
          error: _ctx.form.lastnameErr,
          class: "lg:w-1/2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BaseInput, {
              modelValue: _ctx.form.lastname,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.lastname) = $event)),
              class: "dark-bg",
              name: "lastname"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label", "error"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_InputGroup, {
          label: _ctx.t('lm_request_email_label'),
          error: _ctx.form.emailErr,
          class: "lg:w-1/2 lg:mr-2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BaseInput, {
              modelValue: _ctx.form.email,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.email) = $event)),
              class: "dark-bg",
              name: "email"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label", "error"]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_InputGroup, {
            label: _ctx.t('lm_request_tel_label'),
            error: _ctx.form.telErr,
            class: "mr-1.5 lg:mr-2 w-3/5"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BaseInput, {
                modelValue: _ctx.form.tel,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.tel) = $event)),
                class: "dark-bg",
                name: "tel"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label", "error"]),
          _createVNode(_component_InputGroup, {
            label: _ctx.t('lm_request_zip_label'),
            error: _ctx.form.zipErr,
            class: "w-2/5 lg:w-1/2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BaseInput, {
                modelValue: _ctx.form.zip,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.zip) = $event)),
                class: "dark-bg",
                name: "zip"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label", "error"])
        ])
      ]),
      _createVNode(_component_InputGroup, {
        label: _ctx.t('lm_product_request_comments_label'),
        error: _ctx.form.commentsErr
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BaseTextArea, {
            modelValue: _ctx.form.comments,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.comments) = $event)),
            class: "comments-input",
            name: "comments"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label", "error"]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_BaseText, {
          variant: "16-17",
          class: "optional-text"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('lm_request_required_text')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_BaseButton, {
          type: "submit",
          class: "lg:w-max",
          size: 2,
          variant: "primary",
          disabled: !_ctx.isValid
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('product_details_start_request_btn')), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ])
    ], 32)
  ], 64))
}