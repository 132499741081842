import { object, string } from 'yup';
import { phoneRegEx, postcodeRegEx } from '@/shared/constants/regExpressions';
import { TranslationFun } from '@/shared/composables/useTranslations';

export const lmProductRequestValidationSchema = (t: TranslationFun) =>
  object({
    company: string(),
    firstname: string().required(t('error_message_firstname')),
    lastname: string().required(t('error_message_lastname')),
    email: string().email(t('error_message_email_valid')).required(t('error_message_email')),
    tel: string().matches(phoneRegEx, {
      message: t('error_message_tel'),
    }),
    zip: string()
      .matches(postcodeRegEx, {
        message: t('error_message_zip'),
      })
      .required(t('error_message_zip')),
    comments: string()
      .max(600, t('error_message_comments'))
      .required(t('error_message_comments_required')),
  });
