import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-165d205d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-gray-200" }
const _hoisted_2 = { class: "flex lg:w-1/2 items-center" }
const _hoisted_3 = { class: "product-info-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductTitle = _resolveComponent("ProductTitle")!
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_ProductImage = _resolveComponent("ProductImage")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_ProductPricing = _resolveComponent("ProductPricing")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseContainer, { class: "container" }, {
      default: _withCtx(() => [
        _createVNode(_component_ProductTitle, {
          class: "mb-2 lg:hidden",
          name: _ctx.product.name,
          model: _ctx.product.model
        }, null, 8, ["name", "model"]),
        _createVNode(_component_BackButton, { class: "hidden lg:block w-5 h-full mr-3.5" }),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.product.imageUrl)
            ? (_openBlock(), _createBlock(_component_ProductImage, {
                key: 0,
                src: _ctx.product.imageUrl,
                alt: "Product",
                class: "product-image"
              }, null, 8, ["src"]))
            : (_openBlock(), _createBlock(_component_BaseIcon, {
                key: 1,
                icon: _ctx.getFallbackImage(_ctx.product),
                alt: "Product",
                class: "w-full lg:h-auto p-[20%] text-gray-1700"
              }, null, 8, ["icon"]))
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_ProductTitle, {
            class: "hidden lg:flex",
            name: _ctx.product.name,
            model: _ctx.product.model
          }, null, 8, ["name", "model"]),
          _createVNode(_component_ProductPricing, {
            class: "mt-4 lg:mt-7.5 2xl:mt-13",
            pricing: _ctx.product.pricing,
            insurance: Number(_ctx.product.insurance),
            coverage: Number(_ctx.product.selfCoverage)
          }, null, 8, ["pricing", "insurance", "coverage"]),
          _createVNode(_component_BaseButton, {
            to: _ctx.routes.productAddToCart.path(_ctx.product.id),
            component: "router-link",
            class: "product-button",
            size: 2,
            variant: "primary"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('product_details_start_request_btn')), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ])
      ]),
      _: 1
    })
  ]))
}