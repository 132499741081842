
import { defineComponent } from 'vue';
import { useVersions } from '@/shared/composables/useVersions';
import ProductDetails from './ProductDetails.vue';
import ProductDetailsLM from './ProductDetailsLM.vue';

export default defineComponent({
  name: 'ProductDetailsWrapper',
  components: {
    ProductDetails,
    ProductDetailsLM,
  },
  setup() {
    const { isLm } = useVersions();

    return { isLm };
  },
});
