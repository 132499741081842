
import { defineComponent, PropType, ref } from 'vue';
import debounce from 'lodash/debounce';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.min.css';
import { routes } from '@/shared/constants/routes';
import { useTranslations } from '@/shared/composables/useTranslations';
import { ProductDetails } from '@/shared/interfaces/productDetails';
import ProductCard from '@/shared/components/ProductCard/ProductCard.vue';

const THROTTLE_SWIPER_RELOAD_TIME = 50;

export default defineComponent({
  name: 'ProductsBlock',
  components: {
    ProductCard,
    Swiper,
    SwiperSlide,
  },
  props: {
    products: { type: Array as PropType<ProductDetails[]>, required: true },
    isDark: { type: Boolean, default: false },
  },
  setup() {
    const { t } = useTranslations();
    const swiper = ref<unknown>(null);
    const buttonKey = ref(false);

    const swiperOptions = {
      0: { slidesPerView: 2, spaceBetween: 12 },
      1024: { slidesPerView: 4, spaceBetween: 24, allowTouchMove: false },
    };

    const onSwiper = (swr: unknown) => {
      swiper.value = swr;
    };

    const onResize = debounce((swr: unknown) => {
      swiper.value = swr;
      buttonKey.value = !buttonKey.value;
    }, THROTTLE_SWIPER_RELOAD_TIME);

    return { t, routes, swiper, onSwiper, swiperOptions, buttonKey, onResize };
  },
});
