
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductTitle',
  props: {
    name: { type: String, required: true },
    model: { type: String, required: true },
  },
});
