
import { defineComponent, PropType } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import { useNumbers } from '@/shared/composables/useNumbers';
import { ProductPricing } from '@/shared/interfaces/productDetails';
import { useVersions } from '@/shared/composables/useVersions';

export default defineComponent({
  name: 'ProductPricingDefault',
  props: {
    pricing: { type: Object as PropType<ProductPricing>, required: true },
    insurance: { type: Number, default: 0 },
    coverage: { type: Number, default: 0 },
  },
  setup(props) {
    const { t } = useTranslations();
    const { cPerDay, c } = useNumbers();
    const { isPl } = useVersions();

    const formatDiscount = (price: number) => `-${price.toFixed(0)}%`;

    const weeklyDiscount = isPl
      ? formatDiscount(
          (props.pricing.daily * 7 - props.pricing.weekly) / ((props.pricing.daily * 7) / 100)
        )
      : formatDiscount((props.pricing.daily - props.pricing.weekly) / (props.pricing.daily / 100));

    const monthlyDicount = isPl
      ? formatDiscount(
          (props.pricing.daily * 30 - props.pricing.monthly) / ((props.pricing.daily * 30) / 100)
        )
      : formatDiscount((props.pricing.daily - props.pricing.monthly) / (props.pricing.daily / 100));

    const priceString = (price: number) => (isPl ? c(price) : cPerDay(price));

    return {
      t,
      cPerDay,
      priceString,
      c,
      weeklyDiscount,
      monthlyDicount,
    };
  },
});
