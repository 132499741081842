import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12005274"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white" }
const _hoisted_2 = { class: "lg:w-1/2" }
const _hoisted_3 = { class: "mt-3.5 lg:w-1/2 lg:mt-0" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "flex flex-col lg:flex-wrap lg:flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AttributesContainer = _resolveComponent("AttributesContainer")!
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseContainer, { class: "container" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_AttributesContainer, {
            attributes: _ctx.product.attributes,
            "custom-attributes": _ctx.product.customAttributes,
            productId: _ctx.product.id
          }, null, 8, ["attributes", "custom-attributes", "productId"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BaseHeading, { class: "description-title" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('product_details_description')), 1)
            ]),
            _: 1
          }),
          (_ctx.product.is_attachment)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_BaseText, { class: "description-text mb-1" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('attachment_details_attr_title')), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product.categories, (category) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: category.id,
                      class: "description-category"
                    }, _toDisplayString(category.title), 1))
                  }), 128))
                ]),
                _createVNode(_component_BaseText, { class: "description-text mt-2" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.product.description), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_BaseText, { class: "description-text mt-2" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('attachment_details_attr_disclaimer')), 1)
                  ]),
                  _: 1
                })
              ]))
            : (_openBlock(), _createBlock(_component_BaseText, {
                key: 1,
                class: "description-text"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.product.description), 1)
                ]),
                _: 1
              })),
          (_ctx.product?.producer_link)
            ? (_openBlock(), _createBlock(_component_BaseButton, {
                key: 2,
                class: "mt-4 w-full lg:w-max",
                component: "a",
                href: _ctx.product?.producer_link
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('product_details_producer_link_liebherr')), 1)
                ]),
                _: 1
              }, 8, ["href"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    })
  ]))
}