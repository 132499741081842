import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28ca43c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "price-card lg:mt-3 2xl:mt-4"
}
const _hoisted_2 = {
  key: 1,
  class: "price-card"
}
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = {
  key: 0,
  class: "discount"
}
const _hoisted_5 = {
  key: 2,
  class: "price-card"
}
const _hoisted_6 = { class: "flex" }
const _hoisted_7 = {
  key: 0,
  class: "discount"
}
const _hoisted_8 = {
  key: 3,
  class: "additional-price lg:mt-3 2xl:mt-5"
}
const _hoisted_9 = {
  key: 4,
  class: "additional-price lg:mt-1.5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseText = _resolveComponent("BaseText")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BaseText, {
      variant: "18-20",
      class: "block font-bold 2xl:text-22 mb-1.5"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t('product_details_pricing_title')), 1)
      ]),
      _: 1
    }),
    (_ctx.pricing.daily)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_BaseText, { variant: "18-20" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('product_details_price_daily')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_BaseText, { variant: "18-20" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.priceString(_ctx.pricing.daily || 0)), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.pricing.weekly)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_BaseText, { variant: "18-20" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('product_details_price_weekly')), 1)
              ]),
              _: 1
            }),
            (_ctx.pricing.daily)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_BaseText, { class: "text-14 font-medium" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.weeklyDiscount), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_BaseText, { variant: "18-20" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.priceString(_ctx.pricing.weekly || 0)), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.pricing.monthly)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_BaseText, { variant: "18-20" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('product_details_price_monthly')), 1)
              ]),
              _: 1
            }),
            (_ctx.pricing.daily)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_BaseText, { class: "text-14 font-medium" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.monthlyDicount), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_BaseText, { variant: "18-20" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.priceString(_ctx.pricing.monthly || 0)), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.insurance)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_BaseText, { variant: "18-20" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('product_details_insurance')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_BaseText, { variant: "18-20" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.cPerDay(_ctx.insurance)), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.coverage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_BaseText, { variant: "18-20" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('product_details_self_coverage')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_BaseText, { variant: "18-20" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.c(_ctx.coverage)), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}