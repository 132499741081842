import { ref, Ref } from 'vue';
import { injectStrict } from '@/shared/utils/injectStrict';
import { LmProductRequestKey } from '../constants/injectables';
import { LmProductRequest } from '@/shared/interfaces/lmProductRequest';

interface LmProductRequestResponse {
  isSuccess: Ref<boolean>;
  error: Ref<boolean>;
  isLoading: Ref<boolean>;
  postRequest: (values: LmProductRequest) => Promise<void>;
}

export const useLmProductRequest = (): LmProductRequestResponse => {
  const service = injectStrict(LmProductRequestKey);
  const isSuccess = ref<boolean>(false);
  const isLoading = ref<boolean>(false);
  const error = ref<boolean>(false);

  const postRequest = async (values: LmProductRequest) => {
    isLoading.value = true;
    try {
      const res = await service.requestLmProduct(values as LmProductRequest);
      if (res.status === 200) {
        isSuccess.value = true;
      } else {
        error.value = true;
      }
    } catch (err) {
      error.value = true;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    postRequest,
    isSuccess,
    error,
    isLoading,
  };
};
