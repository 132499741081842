
import { defineComponent, watch } from 'vue';
import { useProductDetails } from './composables/useProductDetails';
import { useProductAttachments } from './composables/useProductAttachments';
import { useProductSuggestions } from './composables/useProductSuggestions';
import { useRouteParams } from '@/shared/composables/useRouteParams';
import { useTranslations } from '@/shared/composables/useTranslations';
import ProductLandingBlockLM from './components/ProductLandingBlockLM.vue';
import ProductDetailsBlock from './components/ProductDetailsBlock.vue';
import ProductsBlock from './components/ProductsBlock.vue';
import Error from '@/pages/Error.vue';

export default defineComponent({
  name: 'ProductDetails',
  components: {
    ProductLandingBlockLM,
    ProductDetailsBlock,
    ProductsBlock,
    Error,
  },
  setup() {
    const { t } = useTranslations();

    const { id } = useRouteParams();
    const { product, error } = useProductDetails(id);
    const { products: attachments, getAttachments } = useProductAttachments();
    const { products: suggestions, getSuggestions } = useProductSuggestions();

    watch(product, (value) => {
      if (value) {
        getAttachments(value);
        getSuggestions(value);
      }
    });

    return { t, product, error, attachments, suggestions };
  },
});
