import { ref, Ref } from 'vue';
import { injectStrict } from '@/shared/utils/injectStrict';
import { ProductDetailsKey } from '../constants/injectables';
import { Product } from '@/shared/interfaces/product';
import { ProductDetails } from '@/shared/interfaces/productDetails';

interface IUseProductSuggestions {
  products: Ref<Product[] | undefined>;
  error: Ref<unknown>;
  isLoading: Ref<boolean>;
  getSuggestions: (product: ProductDetails) => void;
}

export const useProductSuggestions = (): IUseProductSuggestions => {
  const service = injectStrict(ProductDetailsKey);
  const products = ref<Product[]>();
  const isLoading = ref<boolean>(false);
  const error = ref();

  const getCategoriesIds = (product: ProductDetails) => {
    const { categories, deepestCategoryId } = product;
    const categoriesIds = categories.map((item) => item.id.toString());
    categoriesIds.unshift(deepestCategoryId.toString());
    return categoriesIds;
  };

  const fetchSuggestions = async (productId: number, categoriesIds: string[]) => {
    isLoading.value = true;
    try {
      const res = await service.getProductSuggestions(categoriesIds[0]);
      categoriesIds.shift();
      if (res.status === 200) {
        const suggestions = res.data.filter((product) => product.id !== productId);
        if (suggestions.length || !categoriesIds.length) products.value = suggestions;
        else fetchSuggestions(productId, categoriesIds);
      } else {
        error.value = res;
      }
    } catch (err) {
      error.value = err;
    } finally {
      isLoading.value = false;
    }
  };

  const getSuggestions = async (product: ProductDetails) => {
    const categoriesIds = getCategoriesIds(product);
    fetchSuggestions(product.id, categoriesIds);
  };

  return {
    products,
    error,
    isLoading,
    getSuggestions,
  };
};
