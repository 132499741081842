import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f1f4cb9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ProductCard = _resolveComponent("ProductCard")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SwiperSlide = _resolveComponent("SwiperSlide")!
  const _component_Swiper = _resolveComponent("Swiper")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'bg-gray-200': _ctx.isDark })
  }, [
    _createVNode(_component_BaseContainer, { class: "container" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true),
          (_ctx.swiper)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["flex items-start", [
            { 'hidden-controls': _ctx.products.length < 5 },
            { 'hidden-controls-mobile': _ctx.products.length < 3 },
          ]])
              }, [
                _createVNode(_component_BaseButton, {
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.swiper.slidePrev()), ["prevent"])),
                  class: "mr-1 lg:mr-2",
                  variant: "text",
                  size: 0,
                  disabled: _ctx.swiper.isBeginning
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BaseIcon, {
                      icon: "next",
                      class: "w-4 lg:w-6 rotate-180"
                    })
                  ]),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_BaseButton, {
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.swiper.slideNext()), ["prevent"])),
                  variant: "text",
                  key: _ctx.buttonKey,
                  size: 0,
                  disabled: _ctx.swiper.isEnd
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BaseIcon, {
                      icon: "next",
                      class: "w-4 lg:w-6"
                    })
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ], 2))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_Swiper, {
          breakpoints: _ctx.swiperOptions,
          onSwiper: _ctx.onSwiper,
          onResize: _ctx.onResize
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
              return (_openBlock(), _createBlock(_component_SwiperSlide, {
                key: product.id
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_router_link, {
                    to: _ctx.routes.productDetails.path(product.id),
                    class: "w-full mb-3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ProductCard, {
                        product: product,
                        isSmall: "",
                        isDark: _ctx.isDark
                      }, null, 8, ["product", "isDark"])
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["breakpoints", "onSwiper", "onResize"])
      ]),
      _: 3
    })
  ], 2))
}