
import { defineComponent, PropType } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import { ProductDetails } from '@/shared/interfaces/productDetails';
import ProductTitle from './ProductTitle.vue';
import ProductPricing from './ProductPricing.vue';
import { routes } from '@/shared/constants/routes';
import { useFallbackImage } from '@/shared/composables/useFallbackImage';

export default defineComponent({
  name: 'ProductDetailsLandingBlock',
  components: {
    ProductPricing,
    ProductTitle,
  },
  props: {
    product: { type: Object as PropType<ProductDetails>, required: true },
  },
  setup() {
    const { t } = useTranslations();
    const { getFallbackImage } = useFallbackImage();
    return { t, routes, getFallbackImage };
  },
});
