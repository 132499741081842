
import { defineComponent, PropType } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';

export default defineComponent({
  name: 'AttributesContainer',
  props: {
    attributes: { type: Object as PropType<Record<string, string>>, required: true },
    customAttributes: { type: Object as PropType<Record<string, string>>, required: true },
    productId: { type: Number, required: true },
  },
  setup() {
    const { t } = useTranslations();
    return { t };
  },
});
