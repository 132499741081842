
import { defineComponent, PropType } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import { useVersions } from '@/shared/composables/useVersions';
import { ProductDetails } from '@/shared/interfaces/productDetails';
import AttributesContainer from './AttributesContainer.vue';
import BaseButton from '@/shared/components/BaseComponents/BaseButton/BaseButton.vue';

export default defineComponent({
  name: 'ProductDetailsBlock',
  components: { AttributesContainer, BaseButton },
  props: {
    product: { type: Object as PropType<ProductDetails>, required: true },
  },
  setup() {
    const { t } = useTranslations();
    const { isLm } = useVersions();

    return { t, isLm };
  },
});
