import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30a6e360"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "attributes" }
const _hoisted_2 = { class: "atrributes-container" }
const _hoisted_3 = { class: "attributes-card" }
const _hoisted_4 = {
  key: 0,
  class: "attributes-card"
}
const _hoisted_5 = {
  key: 0,
  class: "attributes-card"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_BaseText = _resolveComponent("BaseText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseHeading, { class: "attributes-title" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t('product_details_attr')), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BaseText, { class: "text-18" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('product_details_attr_id')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_BaseText, { class: "text-18" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.productId), 1)
          ]),
          _: 1
        })
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attributes, (attr, name) => {
      return (_openBlock(), _createElementBlock("div", {
        key: name,
        class: "atrributes-container"
      }, [
        attr
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_BaseText, { class: "text-18" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t(`product_details_attr_${name}`)), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_BaseText, { class: "text-18" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(attr), 1)
                ]),
                _: 2
              }, 1024)
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customAttributes, (attr, name) => {
      return (_openBlock(), _createElementBlock("div", {
        key: name,
        class: "atrributes-container"
      }, [
        attr
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_BaseText, { class: "text-18" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(name), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_BaseText, { class: "text-18" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(attr), 1)
                ]),
                _: 2
              }, 1024)
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}