
import { defineComponent, PropType } from 'vue';
import { useForm } from 'vee-validate';
import { useRouter } from 'vue-router';
import { useTranslations } from '@/shared/composables/useTranslations';
import { useOpenable } from '@/shared/composables/useOpenable';
import { ProductDetails } from '@/shared/interfaces/productDetails';
import ProductTitle from './ProductTitle.vue';
import ProductRequestFormLM from './ProductRequestFormLM.vue';
import RequestLmSuccessModal from '@/pages/Home/components/RequestLmSuccessModal.vue';
import { routes } from '@/shared/constants/routes';
import { useFallbackImage } from '@/shared/composables/useFallbackImage';
import { LM_PRODUCT_REQUEST_INITIAL_DATA } from '@/shared/constants/lmProductRequest';
import { lmProductRequestValidationSchema } from '../composables/lmProductRequestValidationSchema';
import { LmProductRequest } from '@/shared/interfaces/lmProductRequest';
import { useLmProductRequest } from '../composables/useLmProductRequest';

export default defineComponent({
  name: 'ProductDetailsLandingBlock',
  components: {
    ProductTitle,
    ProductRequestFormLM,
    RequestLmSuccessModal,
  },
  props: {
    product: { type: Object as PropType<ProductDetails>, required: true },
  },
  setup(props) {
    const { t } = useTranslations();
    const router = useRouter();
    const { getFallbackImage } = useFallbackImage();
    const { isOpen: showModal, open: openModal, close } = useOpenable();
    const { postRequest, isSuccess } = useLmProductRequest();

    const { values, meta } = useForm({
      validationSchema: lmProductRequestValidationSchema(t),
      initialValues: LM_PRODUCT_REQUEST_INITIAL_DATA,
      validateOnMount: false,
    });

    const onSubmit = async () => {
      const products = [{ id: props.product.id.toString() }];

      await postRequest({
        ...values,
        products,
      } as LmProductRequest);
      if (isSuccess.value) openModal();
      else router.push({ path: routes.error.path });
    };

    return { t, routes, getFallbackImage, close, showModal, onSubmit, meta };
  },
});
