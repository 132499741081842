
import { defineComponent } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import { routes } from '@/shared/constants/routes';
import { useProductRequestFields } from '../composables/useProductRequestFields';

export default defineComponent({
  name: 'ProductRequestFormLM',
  emits: ['submit'],
  props: {
    isValid: { type: Boolean, default: false },
  },
  setup() {
    const { t } = useTranslations();
    const { form } = useProductRequestFields();

    return { t, routes, form };
  },
});
